.site-page {
    height: 100vh;

    .site-sider {

        .sider-main {
            width: 120px;
            height: 100vh;
            float: left;

            .site-logo {
                width: 100%;
                height: 64px;
                line-height: 64px;
                float: left;
                text-align: center;
                color: #ffffff;
                font-size: 16px;
            }
        }

        .sider-second {
            height: calc(~"100vh - 64px");
            margin-top: 64px;
            width: 140px;
            float: left;
            background-color: #fff;
            overflow-y: auto;
        }
    }

    .nav-icon {
        font-size: 18px;
    }


    .site-header {
        position: fixed;
        left: 120px;
        right: 0;
        z-index: 10;
        width: calc(~"100vw - 120px");
        padding: 0 24px;
        background-color: #ffffff;
        box-shadow: 0 1px 4px #00152914;

        .site-icon-box {
            float: left;

            .header-icon {
                font-size: 18px;
                margin-right: 24px;
            }
        }

        .site-breadcrumb {
            margin-left: 40px;
            line-height: 64px;
            display: block;
            float: left;
        }


        .site-setting {
            position: absolute;
            right: 24px;

            .setting-item {
                margin: 0 16px;
            }
        }
    }

    .site-body {
        margin: 24px;
        margin-top: 78px;
        height: calc(~"100vh - 78px");
        position: relative;
        overflow-x: hidden;
    }

    .site-footer {
        text-align: center;
        margin: 20px 0;

        .footer-logo {
            height: 24px;
        }
    }
}

.main-body {
    padding: 24px;
    background-color: #ffffff;

    /***基础公共样式***/
    .margin-left15 {
        margin-left: 15px;
    }

    .margin-right15 {
        margin-right: 15px;
    }

    .margin-bottom16 {
        margin-bottom: 16px;
    }

    .table-operator {
        margin-bottom: 16px;

        .line-left {
            text-align: left;
        }

        .lin-right {
            text-align: right;
        }
    }

    .table-search {
        margin-bottom: 16px;
        padding: 16px;
        border: 1px solid #eef1f5;

        .ant-form-item {
            margin: 10px 0;
        }

        .search-item {
            width: 180px;
        }

        .search-button {
            margin-left: 100px;
        }

        .divider-span {
            display: inline-block;
            margin: 0 10px;
        }

        .search-handle {
            margin: 10px 100px;
        }

        .handle-item {
            margin-right: 20px;
        }
    }

    .breadcrumb {
        margin-bottom: 20px;
    }



    .medium-input {
        width: 200px;
    }

    .half-input {
        width: 450px;
    }

    .medium-large-input {
        width: 500px;
    }

    .form-submit-layout {
        margin-left: 100px;
    }

    .page-attention {
        p {
            color: #b2b2b2;
            padding-left: 10px;
            border-left: 4px solid #ebebeb;
            margin: 16px 0;
        }
    }

    .order-attention {
        p {
            color: #333333;
            padding-left: 10px;
            border-left: 4px solid #000000;
            margin: 16px 0;
        }
    }

    .line-content {
        display: flex;
        font-size: 14px;
        color: #a4a4a4;
        margin-bottom: 14px;

        .line-label {
            min-width: 85px;
            color: #a4a4a4;
        }

        .line-text {
            color: #333333;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
        }

        .danger {
            color: #ff4d4f;
        }
    }

}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 6px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    border-radius: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.1);
}
@primary-color: #7f7fd5;@link-color: #86a8e7;