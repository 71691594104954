.site-page {
  height: 100vh;
}
.site-page .site-sider .sider-main {
  width: 120px;
  height: 100vh;
  float: left;
}
.site-page .site-sider .sider-main .site-logo {
  width: 100%;
  height: 64px;
  line-height: 64px;
  float: left;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
}
.site-page .site-sider .sider-second {
  height: calc(100vh - 64px);
  margin-top: 64px;
  width: 140px;
  float: left;
  background-color: #fff;
  overflow-y: auto;
}
.site-page .nav-icon {
  font-size: 18px;
}
.site-page .site-header {
  position: fixed;
  left: 120px;
  right: 0;
  z-index: 10;
  width: calc(100vw - 120px);
  padding: 0 24px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px #00152914;
}
.site-page .site-header .site-icon-box {
  float: left;
}
.site-page .site-header .site-icon-box .header-icon {
  font-size: 18px;
  margin-right: 24px;
}
.site-page .site-header .site-breadcrumb {
  margin-left: 40px;
  line-height: 64px;
  display: block;
  float: left;
}
.site-page .site-header .site-setting {
  position: absolute;
  right: 24px;
}
.site-page .site-header .site-setting .setting-item {
  margin: 0 16px;
}
.site-page .site-body {
  margin: 24px;
  margin-top: 78px;
  height: calc(100vh - 78px);
  position: relative;
  overflow-x: hidden;
}
.site-page .site-footer {
  text-align: center;
  margin: 20px 0;
}
.site-page .site-footer .footer-logo {
  height: 24px;
}
.main-body {
  padding: 24px;
  background-color: #ffffff;
  /***基础公共样式***/
}
.main-body .margin-left15 {
  margin-left: 15px;
}
.main-body .margin-right15 {
  margin-right: 15px;
}
.main-body .margin-bottom16 {
  margin-bottom: 16px;
}
.main-body .table-operator {
  margin-bottom: 16px;
}
.main-body .table-operator .line-left {
  text-align: left;
}
.main-body .table-operator .lin-right {
  text-align: right;
}
.main-body .table-search {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #eef1f5;
}
.main-body .table-search .ant-form-item {
  margin: 10px 0;
}
.main-body .table-search .search-item {
  width: 180px;
}
.main-body .table-search .search-button {
  margin-left: 100px;
}
.main-body .table-search .divider-span {
  display: inline-block;
  margin: 0 10px;
}
.main-body .table-search .search-handle {
  margin: 10px 100px;
}
.main-body .table-search .handle-item {
  margin-right: 20px;
}
.main-body .breadcrumb {
  margin-bottom: 20px;
}
.main-body .medium-input {
  width: 200px;
}
.main-body .half-input {
  width: 450px;
}
.main-body .medium-large-input {
  width: 500px;
}
.main-body .form-submit-layout {
  margin-left: 100px;
}
.main-body .page-attention p {
  color: #b2b2b2;
  padding-left: 10px;
  border-left: 4px solid #ebebeb;
  margin: 16px 0;
}
.main-body .order-attention p {
  color: #333333;
  padding-left: 10px;
  border-left: 4px solid #000000;
  margin: 16px 0;
}
.main-body .line-content {
  display: flex;
  font-size: 14px;
  color: #a4a4a4;
  margin-bottom: 14px;
}
.main-body .line-content .line-label {
  min-width: 85px;
  color: #a4a4a4;
}
.main-body .line-content .line-text {
  color: #333333;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.main-body .line-content .danger {
  color: #ff4d4f;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
}
